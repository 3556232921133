<template>
  <section class="feature">
    <div class="content-split switch-big">
      <div class="content-right">
        <img
          class="hero-feature"
          src="~/assets/marketing/course-hero.png"
          alt="Feature 1"
        />
      </div>
      <div class="content-left align-left spaced-vertically">
        <h3>Create Maps Online</h3>
        <p>
          With Orienteer's intuitive interface, you can create orienteering maps
          in no time. Simply drag and drop markers onto the map, add altitude
          and other checkpoint info, and arrange them as needed. You can even
          customize and print your maps, or upload custom map files to suit your
          course!
        </p>

        <MarketingButton to="/?signup" class="button cta">
          <img
            class="icon"
            src="~/assets/icons/orienteer-flag.svg"
            alt="Feature 1"
          />
          Get Started
        </MarketingButton>
      </div>
    </div>
  </section>
</template>
<style scoped>
section.feature {
  margin-right: 0px;
  overflow: hidden;
}

.hero-feature {
  /* Box properties */
  width: calc(var(--grid-8) * 3.5);
  /* Background & borders */
  border-radius: var(--border-radius-large);
  /* Typography */
  /* Animation */
  /* Other */
}

.content-split {
  /* Box properties */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.content-left,
.content-right {
  /* Box properties */
  box-sizing: border-box;
  width: 50%;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.content-left {
  padding-right: var(--grid-2);
}

.content-left.align-left {
  /* Box properties */
  text-align: right;
  align-items: flex-end;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.content-right {
  /* Box properties */
  /* Background & borders */
  padding-left: var(--grid-2);
  /* Typography */
  /* Animation */
  /* Other */
}

@media screen and (max-width: 1000px) {
  section.feature {
    margin: 0px;
  }
  .content-split {
    flex-direction: column;
    gap: var(--grid);
  }

  .content-left,
  .content-right,
  .content-left.align-left {
    /* Box properties */
    box-sizing: border-box;
    width: 100%;
    padding: 0px var(--grid-2);
  }

  .content-right {
    display: flex;
    padding: 0px;
    justify-content: center;
  }

  .content-right img {
    max-width: 100%;
  }
}
</style>
