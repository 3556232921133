<template>
  <main>
    <MarketingHero />
    <MarketingAppSection />

    <MarketingCreateMaps />

    <section>
      <div class="hero-secondary"></div>
    </section>
  </main>
</template>
<script lang="ts" setup></script>
<style scoped></style>
