<template>
  <section id="hero">
    <div class="content-split">
      <div class="content-left">
        <h1>
          Orienteer is an app that helps you learn to navigate the great
          outdoors with a map and compass.
        </h1>
      </div>
    </div>
    <MarketingButton :to="{ query: { signup: null } }" class="button cta">
      <img class="icon" src="~/assets/orienteer-flag.svg" alt="Feature 1" />
      Get Started
    </MarketingButton>
  </section>
</template>
<script lang="ts" setup></script>
<style scoped>
section#hero {
  /* Box properties */
  display: flex;
  flex-direction: column;
  gap: var(--grid-2);
  align-items: flex-start;

  padding: 6rem 4rem;
  margin-bottom: var(--half-grid);
  overflow: hidden;

  border-radius: var(--border-radius-large);
  background: url("~/assets/marketing/daniil-silantev-ioYwosPYC0U-unsplash.jpg")
    center center no-repeat;
  background-size: cover;

  color: white;
}

@media screen and (max-width: 600px) {
  section#hero {
    padding: 2rem 1.5rem;
  }

  h1 {
  }
}

h1,
h3 {
  text-shadow: var(--text-shadow);
}

.content-split {
  max-width: 35rem;
}

#hero-image {
  /* Box properties */
  /* Background & borders */
  width: 100%;
  height: calc(var(--grid) * 40);
  background: url("~/assets/marketing/daniil-silantev-ioYwosPYC0U-unsplash.jpg")
    center bottom no-repeat;
  background-size: cover;
  /* Typography */
  /* Animation */
  /* Other */
}
</style>
