<template>
  <section id="sub-hero">
    <div class="content-split">
      <div class="content-left">
        <img
          class="hero-phone"
          src="~/assets/marketing/phone-hero-three.png"
          alt="Feature 1"
        />
      </div>
      <div class="content-right spaced-vertically">
        <div class="icon-row">
          <img
            class="icon icon-quiet"
            src="~/assets/icons/cellphone-marker.svg"
            alt="Feature 1"
          />
          <img
            class="icon icon-quiet"
            src="~/assets/icons/map-plus.svg"
            alt="Feature 1"
          />
          <img
            class="icon icon-quiet"
            src="~/assets/icons/compass-outline.svg"
            alt="Feature 1"
          />
          <img
            class="icon icon-quiet"
            src="~/assets/icons/map-marker-multiple-outline.svg"
            alt="Feature 1"
          />
          <img
            class="icon icon-quiet"
            src="~/assets/icons/map-marker-path.svg"
            alt="Feature 1"
          />
          <img
            class="icon icon-quiet"
            src="~/assets/icons/map-marker-star.svg"
            alt="Feature 1"
          />
        </div>
        <p>
          Whether you're a seasoned pro or a beginner, our app makes it easy to
          plan, track, and share orienteering courses. With features such as
          interactive maps, compass, and tracking, you'll learn the sport
          quickly.
        </p>
        <div class="app-links">
          <AppStoreLink />
          <!--<a
            class="hero-link download-ios"
            href="https://itunes.apple.com/app/breadwallet/id885251393"
            >Apple App Store</a
          >-->
          <span class="shame-link-spacer">&nbsp;</span>
          <PlayStoreLink />
          <!--<a
            class="hero-link download-google"
            href="https://play.google.com/store/apps/details?id=com.breadwallet"
            >Google Play Store</a
          >-->
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
section#sub-hero {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

#sub-hero {
  /* Box properties */
  padding: 6rem 4rem 2rem;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.app-links {
  display: flex;
  align-items: center;
  gap: var(--grid);
}

.hero-phone {
  /* Box properties */
  width: calc(var(--grid-8) * 3.5);
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

section.feature {
  /* Box properties */
  padding: 2rem 4rem;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.hero-feature {
  /* Box properties */
  width: calc(var(--grid-8) * 3.5);
  /* Background & borders */
  border-radius: var(--border-radius-large);
  /* Typography */
  /* Animation */
  /* Other */
}

.hero-secondary {
  /* Box properties */
  margin-bottom: 6rem;
  width: 100%;
  height: calc(var(--grid) * 40);
  /* Background & borders */
  border-radius: var(--border-radius-large);
  background: url("~/assets/marketing/jeremy-bishop-Fuj9pNrZpYQ-unsplash.jpg")
    center bottom no-repeat;
  background-size: cover;
  /* Typography */
  /* Animation */
  /* Other */
}

p {
  max-width: 30rem;
}

.icon {
  /* Box properties */
  width: calc(var(--grid) * 1.5);
  height: calc(var(--grid) * 1.5);
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.icon.icon-quiet {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
  opacity: 0.35;
}

.icon-big {
  /* Box properties */
  width: var(--grid-4);
  height: var(--grid-4);
  display: block;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.icon-bigger {
  /* Box properties */
  width: var(--grid-6);
  height: var(--grid-6);
  display: block;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.icon-row {
  /* Box properties */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.icon-row > img {
  /* Box properties */
  margin-right: calc(var(--grid) * 1.5);
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.hero-feature {
  /* Box properties */
  width: calc(var(--grid-8) * 3.5);
  /* Background & borders */
  border-radius: var(--border-radius-large);
  /* Typography */
  /* Animation */
  /* Other */
}

.content-split {
  /* Box properties */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}
.content-left,
.content-right {
  /* Box properties */
  box-sizing: border-box;
  width: 50%;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.content-left {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--grid-2);
}

.content-left.align-left {
  /* Box properties */
  text-align: right;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

.content-right {
  /* Box properties */
  /* Background & borders */
  padding-left: var(--grid-2);
  /* Typography */
  /* Animation */
  /* Other */
}

@media screen and (max-width: 1000px) {
  .content-split {
    flex-direction: column;
    gap: var(--grid);
  }

  .content-left,
  .content-right {
    /* Box properties */
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
  }
}
</style>
